<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('user.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('user.pageTitle'),
                        href: '/admin/users'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-4 tw-mb-5">
                        <UserAccount v-model="model" :validate="errors" class="tw-h-full" />
                    </div>
                    <div class="col-md-8 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <Permission v-model="model" class="tw-h-full" />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 tw-mb-5">
                        <UserBranch v-model="model" :validate="errors" class="tw-h-56" />
                    </div>
                    <div class="col-md-4 tw-mb-5">
                        <UserRole v-model="model" :validate="errors" class="tw-h-56" />
                    </div>
                    <div class="col-md-4 tw-mb-5">
                        <AllowIp v-model="model" :validate="errors" class="tw-h-56" />
                    </div>

                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'user' })"
                class="btn-gray"
            >
                {{ $t("cancel") }}</ts-button
            >
            <ts-button
                color="primary"
                outline
                :waiting="waiting_new"
                :disabled="waiting"
                @click.prevent="onSaveAddNew"
            >
                {{ $t("saveAddNew") }}</ts-button
            >
            <ts-button
                color="primary"
                :waiting="waiting"
                :disabled="waiting_new"
                @click.prevent="onSave"
            >
                {{ $t("save") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import UserAccount from "./components/account.vue";
import UserRole from "./components/role.vue";
import UserBranch from "./components/branch.vue";
import AllowIp from "./components/allow-ip.vue";
import Permission from "./components/permision.vue";
import { mapActions } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "userCreate",
    components: {
        UserAccount,
        UserRole,
        UserBranch,
        Permission,
        AllowIp
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                user_id: 0,
                employee_id: null,
                user_name: "",
                email: "",
                roles: [],
                permissions: [],
                branches: [],
                allow_ip_address: [],
                disable: false,
                password: null,
                password_confirmation: null
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    methods: {
        ...mapActions("auth/user", [
            "getUserRole",
            "getPermissions",
            "getBranches",
            "getEmployees",
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getUserRole();
            await this.getPermissions();
            await this.getBranches();
            await this.getEmployees();
            this.loading = false;
        },
        onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("auth/user/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("auth/user/store", this.model)
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.clearInput();
                    this.$router.push({ name: "user" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        clearInput() {
            (this.model.user_id = 0),
                (this.model.user_name = ""),
                (this.model.email = ""),
                (this.model.roles = []),
                (this.model.permissions = []),
                (this.model.branches = []),
                (this.model.disable = false),
                (this.model.password = null),
                (this.model.employee_id = null),
                (this.model.allow_ip_address = []),
                (this.model.password_confirmation = null);
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "USER",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        next();
    }
};
</script>

